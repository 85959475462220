<template>
    <v-row class="blogblockitem">
        <v-col class="item mb-5 mb-md-10" cols="12" sm="6" lg="4" v-for="(itm, i) in items" :key="i">
            <a :href="itm.link" target="_blank">
                <v-img :src="requireImage(itm.image)" class="image rounded-30" position="top center"/>
                <div class="h4 fontw-700 mx-5 blogtitle mb-10 dark1">{{itm.title}}</div>
                <custom-button
                    class="circle btnblue bg-transparent px-8 fontw-700 h6 d-none d-md-block"
                >
                    Read more <v-icon x-small class="ml-3">mdi-plus</v-icon>
                </custom-button>
            </a>
            <div class="mover"></div>
        </v-col>
    </v-row>
</template>

<script>
import CustomButton from '@/components/base/CustomButton.vue'

export default {
    name:"BlogBlockExternal",
    components: {
        CustomButton
    },
    props:['items'],
    methods: {
        requireImage(image){
            return process.env.VUE_APP_BASE + '/storage/' + image;
        },
    }
}
</script>

<style lang="scss">
.blogblockitem .item:nth-child(3n - 1){
    padding-top: 30px;
}
.blogblockitem{
    .item{
        transition: 0.3s;
        .image{
            // border-radius: 0px;
            transition: 0.3s;
            height: 250px;
        }
        .blogtitle{
            //margin-top: -2rem;
            position: relative;
        }
    }
    @media all and (max-width: 660px){
        .item{
            .image{
                height: 200px;
            }
        }
    }
    @media all and (min-width: 660px){
        .item{
            .image{
                height: 200px;
            }
        }
    }
    @media all and (min-width: 930px){
        .item{
            .image{
                height: 250px;
            }
            .mover{
                height: 25px;
                transition: 0.3s;
            }
        }
        .item:hover{
            margin-top: 25px;
            .image{
                opacity: 0.4;
            }
            .mover{
                height: 0;
            }
        }
    }
}
</style>
