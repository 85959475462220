<template>
    <!-- <v-col cols="12" lg="2" :offset="(ikey == 0 || ikey == 5) ? '1' : '0'"> -->
    <v-hover v-slot="{ hover }">
        <div :class="{ 'bg-blue': hover }" class="companies-card d-flex justify-center align-center mb-5">
            <img v-show="!hover" :src="item.logo">
            <img v-show="hover" :src="item.hover">
        </div>
    </v-hover>
    <!-- </v-col> -->
</template>

<script>
export default {
    props: ['item', 'ikey'],
}
</script>

<style lang="scss">
.companies-card{
    background: #F4F7FF;
    border-radius: 40px;
    width: 227px;
    height: 227px;
    transition: 0.3s;
}
</style>
