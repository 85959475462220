<template>
    <we-are :weare="dat" link="frontend.about">
		<template v-slot:left>
			<p class="h2 montserrat bold relative pt-13 pl-4 pl-sm-0 pt-sm-0">
				<span class="white--text">W</span>e are <br>
				<span class="white--text">G</span>BMF</p>
		</template>
	</we-are>
</template>

<script>
import WeAre from '@/components/page/weare'

export default {
    name: 'WeAreGBMF',
    components: {
        WeAre
    },
    data: () => ({
        dat:[
            {
                title: 'You can rely on GBMF',
                text: 'Whether you want to become an industry apex predator or only to optimize your website, you can rely on GBMF.',
            },
            {
                title: 'We are your best friends',
                text: 'When it comes to developing and designing websites, mobile apps, or any other IT services.',
            },
            {
                title: 'We are not just some obscure team',
                text: 'Our team makes sure that no matter what the project is, it is executed with the client’s needs in focus. We are not just some obscure team that doesn’t let you know what’s going on while working on your project.',
            },
            {
                title: 'We take care of you',
                text: 'Quite the contrary, we take care of you, providing detailed information during every development stage, being open to discussion, and ensure that the final result exceeds your expectations.',
            },
            {
                title: 'Focused on creating high-performance products',
                text: 'Today, GBMF is focused on creating high-performance products that go above and beyond industry standards.',
            },
            {
                title: 'Modern technologies are a huge part of this openness',
                text: 'While being situated in London, our team is open to working with you wherever you are, and modern technologies are a huge part of this openness.',
            },
            {
                title: 'Changing your life and the lives of your audience',
                text: 'We want to provide you with digital products that transcend your bravest dreams while changing your life and the lives of your audience.',
            }
        ],
    })
}
</script>
