<template>
    <div>
        <div class="zi-1 rectangle-bghead d-none d-lg-block">
        </div>
        <v-container>
            <div class="py-10 py-sm-16  my-sm-16">
                <h1 class="h1 montserrat text-center text-md-left fontw-300 lineh96" ref="tapable">
                    <span class="blue1">G</span>{{welcometext['G']}}
                    <span class="blue1">B</span>{{welcometext['B']}}
                    <br/>
                    <span class="blue1">M</span>{{welcometext['M']}}
                    <span class="blue1">F</span>{{welcometext['F']}}
<!-- <span class="typed-text">{{ typeValue }}</span>
<span class="cursor" :class="{'typing': typeStatus}">&nbsp;</span> -->
                </h1>
                <p class="my-12 opensans text-center text-md-left h5" ref="header">Flexibility and responsiveness when <br v-if="$vuetify.breakpoint.smAndDown"/> working on IT projects</p>
                <div class="getstarttrans d-flex flex-column flex-md-row">
                    <span class="d-flex justify-center mr-0 mr-md-2" @click.stop="$refs.getstarted.changeDialogget()">
                        <custom-button class="py-7 px-14 my-2 btn-border-radius btnfullblue white--text fontw-700" large>Get Started</custom-button>
                    </span>
                    <get-started-block  ref="getstarted"/>
                    <span class="d-flex justify-center order-1 order-md-2" @click="runErase">
                        <button-refresh class="my-2">Refresh</button-refresh>
                    </span>
                </div>
            </div>
            <div class="d-flex flex-column flex-md-row justify-space-between my-0 my-lg-16 py-lg-16">
                <div class="d-flex flex-column flex-md-row">
                    <div class="mr-4 my-2 my-md-0" v-for="(item, i) in services" :key="i">
                        <router-link class="text-decoration-none d-flex justify-center dark1 montserrat fontw-500 h6 cursordefault" to="#">
                            <img class="mr-3" :src="require('@/assets/icon/dot.svg')" alt=""> {{item.text}}
                        </router-link>
                    </div>
                </div>

                <div class="d-flex justify-center my-16 my-md-0">
                    <p class="montserrat bold blue1 text-sm">Scroll down <img class="ml-4 mb-1" :src="require('@/assets/icon/vector.svg')" alt="" width="8" height="4"></p>
                </div>
            </div>
            <we-are />
            <div class="bordertopop02 mb-16 white p-relative zi-1">
                <p class="h3-2 montserrat bold text-center mt-8 py-4">Services</p>
                <div class="">
                    <v-row>
                        <v-col class="" v-for="(item, i) in servicescard" :key="i" cols="12" sm="6" md="4">
                            <div class="borderedservicecard text-center h-100">
                                <service-card :item="item" class="h-100" type="cardwhite" :icon="{basetype: 'blue', hovertype: 'white'}"/>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-container>
        <quest />
        <v-container class="my-md-16">
            <p class="h2 montserrat bold pt-16 pb-md-8 text-center text-md-left">Our Projects</p>
            <v-row>
                <v-col v-for="(item,i) in ourwork" :key="i" class="workblock" cols="12" sm="6" data-aos="fade-up">
                    <work-block :item="item" />
                </v-col>
            </v-row>
            <div class="text-center my-16 pb-16" data-aos="fade-up">
                <router-link :to="{ name: 'frontend.works', params: {} }">
                    <button-circle class="h6">View more work  +</button-circle>
                </router-link>
            </div>
        </v-container>
        <v-container class="mt-md-16 my-6">
            <!-- <div class="pb-8 borderbottomop02">
                <p class="h2 montserrat bold mt-md-16 text-md-left" data-aos="fade-right">Technology</p>
                <p class="h4 montserrat text-md-left tehnologydesc" data-aos="fade-right">We are using modern technologies that help us solve any issue you might have.</p>
                <technology-block />
            </div> -->
            <tehnologies />
        </v-container>
        <v-container class="my-16 d-none d-lg-block">
            <p class="h3-2 montserrat bold blogheaderposition my-md-8 text-md-center">Who we work with</p>
            <!-- <v-row> -->
            <div class="d-flex justify-space-between" v-for="(items, z) in companiesComposed" :key="z">
                <companies v-for="(item, i) in items" :key="i" :ikey="i" :item="item">{{i}}</companies>
            </div>
            <!-- </v-row> -->
        </v-container>

        <v-container class="my-16 d-lg-none">
            <p class="h3-2 montserrat bold blogheaderposition my-md-8 text-md-center">Who we work with</p>
            <v-row>
                <v-col cols="6" sm="6" md="3" v-for="(item, z) in companies" :key="z" class="text-center">
                    <companies :item="item" class="mx-auto mw-100"></companies>
                </v-col>
            </v-row>
        </v-container>

        <v-container class="mb-md-16">
            <p class="h3-2 montserrat bold blogheaderposition my-md-8 text-md-center">GBMF Blog</p>
            <blog-block :items="blog"/>
            <!-- <v-row class="mb-16">
                <v-col v-for="(item,i) in blogblock" :key="i" cols="12" sm="6" data-aos="fade-up">
                    <div class="border-radius-blog">
                        <v-img :src="item.bg"></v-img>
                    </div>
                </v-col>
            </v-row> -->
            <div class="text-center my-4" data-aos="fade-up">
                <a href="https://blog.gbmf.tech/" target="_blank">
                    <button-circle>View Blog  +</button-circle>
                </a>
            </div>
        </v-container>
    </div>
</template>

<script>
import { setTimeout } from 'timers';
import { Affix } from 'vue-affix';

import ServiceCard from '@/components/base/ServiceCard.vue'
import WorkBlock from '@/components/base/WorkBlock.vue'
import BlogBlock from '@/components/base/BlogBlockExternal.vue'
import Companies from '@/components/base/Companies.vue'
import GetStartedBlock from '@/components/base/GetStartedBlock.vue'
import CustomButton from '@/components/base/CustomButton.vue'

import WeAre from '@/components/page/weare/gbmf'
import Quest from '@/components/page/quest.vue'
import Tehnologies from '@/components/page/tehnologies.vue'

export default {
    metaInfo() {
        return {
            meta: [
                { name: 'description', content:  'Your first choice for getting unique results. GBMF Tech focuses on delivering outstanding software products, combining skill, knowledge, and innovation.'},
                { property: 'og:title', content: "GBMF Tech | A high-performance IT development company"},
                { property: 'og:site_name', content: 'GBMF'},
                { property: 'og:type', content: 'website'},
                { name: 'robots', content: 'index,follow'}
            ]
        }
    },
    components:{
        ServiceCard,
        WorkBlock,
        BlogBlock,
        GetStartedBlock,
        Affix,
        Companies,
        WeAre,
        Quest,
        CustomButton,
        Tehnologies
    },
    data:() => ({
        buttoncirclehover:false,
        onclick:false,
        blog: [],
        welcometext: {
            'G': '',
            'B': '',
            'M': '',
            'F': '',
        },
        currentWord: {
            'G': [],
            'B': [],
            'M': [],
            'F': [],
        },
        blogblock:[
            {
                bg: require('@/assets/images/5key.png'),
                title: '5 keys to successful online learning at home',
                text: 'More schools are providing each student with their own device in class.',
            },
            {
                bg: require('@/assets/images/therole.png'),
                title: 'The role of QA in a shift-left world',
                text: 'The uptake in Agile and DevOps processes has led to changes in how software is written, tested, secured and deployed.',
            },
        ],
        ourwork:[
            // {
            //     img: require('@/assets/images/Xpedite.png'),
            //     title: 'Xpedite Permits',
            //     text: 'Bussines web site',
            // },
            // {
            //     img: require('@/assets/images/Freight.png'),
            //     title: 'Freight Crew',
            //     text: 'Bussines web site',
            // },
            // {
            //     img: require('@/assets/images/Leopay.png'),
            //     title: 'Leo Pay',
            //     text: 'Online payment platform',
            // },
            // {
            //     img: require('@/assets/images/Paystar.png'),
            //     title: 'Pay Star',
            //     text: 'Online payment platform',
            // },
        ],
        companiesComposed: [],
        companies: [
            {
                logo: require('@/assets/icon/logo/android.svg'),
                hover: require('@/assets/icon/logo/android-white.svg')
            },
            {
                logo: require('@/assets/icon/logo/amazon.svg'),
                hover: require('@/assets/icon/logo/amazon-white.svg')
            },
            {
                logo: require('@/assets/icon/logo/ups.svg'),
                hover: require('@/assets/icon/logo/ups-white.svg')
            },
            {
                logo: require('@/assets/icon/logo/circle.svg'),
                hover: require('@/assets/icon/logo/circle-white.svg')
            },
            {
                logo: require('@/assets/icon/logo/harley.svg'),
                hover: require('@/assets/icon/logo/harley-white.svg')
            },
            {
                logo: require('@/assets/icon/logo/toyota.svg'),
                hover: require('@/assets/icon/logo/toyota-white.svg')
            },
            {
                logo: require('@/assets/icon/logo/fedex.svg'),
                hover: require('@/assets/icon/logo/fedex-white.svg')
            },
            {
                logo: require('@/assets/icon/logo/ub.svg'),
                hover: require('@/assets/icon/logo/ub-white.svg')
            },
            {
                logo: require('@/assets/icon/logo/dhl.svg'),
                hover: require('@/assets/icon/logo/dhl-white.svg')
            },
            {
                logo: require('@/assets/icon/logo/fc.svg'),
                hover: require('@/assets/icon/logo/fc-white.svg')
            },
        ],
        refreshtext:[
            {
                'G': 'rowing',
                'B': 'usinesses',
                'M': 'astering the',
                'F': 'uture',
            },
            {
                'G': 'olden',
                'B': 'rands',
                'M': 'ake',
                'F': 'lames',
            },
            {
                'G': 'iving',
                'B': 'older',
                'M': 'eaning to',
                'F': 'uture',
            },
            {
                'G': 'reat',
                'B': 'usinesses',
                'M': 'atter',
                'F': 'orever',
            },
            {
                'G': 'rand',
                'B': 'rand',
                'M': 'aster',
                'F': 'lash',
            },

        ],
        services:[
            {
                vcol: '3',
                text: 'iOS | Android Development',
                link: 'frontend.home',
            },
            {
                vcol: '3',
                text: 'E-commerce Marketplace',
                link: 'frontend.home',
            },
            {
                vcol: '2',
                text: 'Business Websites',
                link: 'frontend.home',
            },
            {
                vcol: '2',
                text: 'CRM System',
                link: 'frontend.home',
            },
        ],
        servicescard:[
            {
                icon: 'android',
                title: 'Android Development',
                text: 'We create an all-encompassing range of superb apps for one of the most popular mobile platforms',
                animation: 'fade-down-right',
            },
            {
                icon: 'marketplace',
                title: 'E-commerce Marketplace',
                text: 'GBMF e-commerce web development services will increase your business performance due to our optimal solutions',
                animation: 'fade-down',
            },
            {
                icon: 'ios',
                title: 'iOS Development',
                text: 'With our astonishing digital products, we bring the best experience to your clients who use Apple devices',
                animation: 'fade-down-left',
            },
            {
                icon: 'business',
                title: 'Business Websites',
                text: 'GBMF delivers highly-available, functional, and visually-engaging web products and solutions for enterprises',
                animation: 'fade-up-right',
            },
            {
                icon: 'crm',
                title: 'CRM',
                text: 'We provide end-to-end customized CRM Integration and CRM Implementation services for efficient management processes',
                animation: 'fade-up',
            },
            {
                icon: 'fintech',
                title: 'FinTech',
                text: 'Our software development services advance both early-stage FinTech startups and established financial organizations',
                animation: 'fade-up-left',
            },
        ],

        typeValue: '',
        typeStatus: false,
        typeArray: ['fun', 'awesome', 'a journey', 'life'],
        typingSpeed: 100,
        erasingSpeed: 75,
        newTextDelay: 2000,
        typeArrayIndex: 0,
        charIndex: 0,
        tmpType: '',
        welcometextposition: 0,
    }),
    created() {
        this.$emit('headerClass', '');
    },
    mounted(){
        this.getdata();
        this.runType(false);
        this.composeItems();

    },
    computed: {
        TYPE_SPEED() { return 500 } ,
        DELETE_SPEED(){ return this.deleteSpeed },
    },
    methods: {
        runType(next = true){
            if(this.typeStatus == false){
                this.typeStatus = true;
                this.startType(next);
            }
        },
        startType(next = true){
            if(next){
                this.welcometextposition += 1;
                if(!this.refreshtext[this.welcometextposition])
                    this.welcometextposition = 0;
            }

            this.currentWord = this.refreshtext[this.welcometextposition];
            this.typeText();
        },
        typeText() {
            let direction = ['G', 'B', 'M', 'F'];

            if(this.charIndex < this.currentWord[direction[this.typeArrayIndex]].length) {
                this.welcometext[direction[this.typeArrayIndex]] += this.currentWord[direction[this.typeArrayIndex]].charAt(this.charIndex);
                this.charIndex += 1;
                setTimeout(this.typeText, this.typingSpeed);
            }
            else if(direction.length > this.typeArrayIndex + 1){
                this.charIndex = 0;
                this.typeArrayIndex += 1;
                setTimeout(this.typeText, this.typingSpeed);
            }
            else{
                this.typeStatus = false;
            }
        },
        runErase(){
            if(this.typeStatus == false){
                this.typeStatus = true;
                this.startErase();
            }
        },
        startErase(){
            this.typeArrayIndex = 0;
            this.charIndex = 0;
            setTimeout(this.eraseText, this.erasingSpeed);
        },
        eraseText() {
            let direction = ['F', 'M', 'B', 'G'];

            if(this.welcometext[direction[this.typeArrayIndex]].length > 0) {
                this.charIndex = this.welcometext[direction[this.typeArrayIndex]].length;
                this.welcometext[direction[this.typeArrayIndex]] = this.welcometext[direction[this.typeArrayIndex]].substring(0, this.charIndex - 1);
                setTimeout(this.eraseText, this.erasingSpeed);
            }
            else if(direction.length > this.typeArrayIndex + 1){
                this.typeArrayIndex += 1;
                setTimeout(this.eraseText, this.erasingSpeed);
            }else{
                this.typeArrayIndex = 0;
                this.charIndex = 0;
                setTimeout(this.startType, this.typingSpeed);
            }
        },
        async getdata(){
            await this.axios.get(process.env.VUE_APP_API + 'index').then(response=>{
                // this.technologyblock = response.data.technologies;
                this.ourwork = response.data.works;
                this.blog = response.data.blogs;

                if(window.innerWidth < 1064 && window.innerWidth >= 900){
                    this.blog.splice(1, 1);
                }
            })
        },
        composeItems(){
            var results = [];
            var companies = JSON.parse(JSON.stringify(this.companies));

			while (companies.length) {
				results.push(companies.splice(0, 5));
			}
			this.companiesComposed = results;
        }
    },
}
</script>
<style lang="scss">
.slide-fade-enter-active{
    transition: all .3s ease;
}
.slide-fade-leave-active{
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to{
/* .slide-fade-leave-active below version 2.1.8 */
    transform: translateX(10px);
    opacity: 0;
}
.borderl::after{
    content: " ";
    border-right: 8px solid #000000;
    margin-left: 15px;
    display: inline-flex;
    width: 8px;
    height: 60px;
}
.borderedservicecard{
    border: 1px solid rgb(50, 50, 50, 0.1);
    box-sizing: border-box;
    border-radius: 40px;
}

@media all and (max-width: 1024px){
    .blogheaderposition{
        text-align: left;
    }
}
</style>
